import { useMemo } from 'react';
import type { ApolloError } from 'apollo-client';

import type { ClassificationOption } from '@atlassian/data-classification-level';

import type { ClassificationLevel } from '../ClassificationRadioOption';

import { useOrgDefaultClassificationLevelId } from './useOrgDefaultClassificationLevelId';
import { useSpaceDefaultClassificationLevelOption } from './useSpaceDefaultClassificationLevelOption';

export type UseComputeDefaultOptionProps = {
	getClassificationLevelFromId: (id?: string | null) => ClassificationLevel | undefined;
	spaceDefaultClassificationLevel?: ClassificationLevel | null;
};

export type UseComputeDefaultOptionType = {
	defaultClassificationOption: ClassificationOption;
	error?: ApolloError;
};

export const useComputeDefaultClassificationDropdownOption = ({
	getClassificationLevelFromId,
	spaceDefaultClassificationLevel,
}: UseComputeDefaultOptionProps): UseComputeDefaultOptionType => {
	const { orgDefaultClassificationLevelId, orgDefaultClassificationError } =
		useOrgDefaultClassificationLevelId({});

	const orgDefaultClassificationLevel = getClassificationLevelFromId(
		orgDefaultClassificationLevelId,
	);

	const defaultLevel = useMemo(() => {
		return spaceDefaultClassificationLevel || orgDefaultClassificationLevel;
	}, [spaceDefaultClassificationLevel, orgDefaultClassificationLevel]);

	const defaultClassificationOption = useSpaceDefaultClassificationLevelOption(defaultLevel);

	return {
		defaultClassificationOption,
		error: orgDefaultClassificationError,
	};
};
