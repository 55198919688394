import type { ApolloQueryResult } from 'apollo-client';
import { useQuery } from 'react-apollo';

import { getApolloClient } from '@confluence/graphql';

import type {
	ContentOperationsQuery as ContentOperationsQueryType,
	ContentOperationsQuery_content_nodes_operations,
} from './__types__/ContentOperationsQuery';
import { ContentOperationsQuery } from './ContentOperationsQuery.graphql';
import type {
	canPerformContentOperationParams,
	canPerformSpaceOperationParams,
	checkContentOperationParams,
	checkSpaceOperationParams,
	UseCanPerformContentOperationType,
	UseCanPerformSpaceOperationType,
} from './operationsTypes';
import type {
	SpaceOperationsQuery as SpaceOperationsQueryType,
	SpaceOperationsQuery_space_operations,
} from './__types__/SpaceOperationsQuery';
import { SpaceOperationsQuery } from './SpaceOperationsQuery.graphql';

/**
 * Validate `operation` on `contentType` given `operationCheckResult` from `ContentOperationsQuery`
 * If `contentType` is not specified, type of main/parent content in `operationCheckResult` will be assumed
 */
export const canPerformContentOperation = ({
	operationCheckResult,
	operation,
	contentType,
}: canPerformContentOperationParams): boolean => {
	const permittedOperations = (operationCheckResult?.content?.nodes?.[0]?.operations ??
		[]) as ContentOperationsQuery_content_nodes_operations[];
	const targetContentType = !!contentType
		? contentType
		: operationCheckResult?.content?.nodes?.[0]?.type;

	if (!targetContentType) return false;

	return permittedOperations.some(
		(permittedOperation) =>
			permittedOperation?.operation === operation &&
			permittedOperation?.targetType === targetContentType,
	);
};

/** Validate `operation` on `contentType` given `operationCheckResult` from `SpaceOperationsQuery */
export const canPerformSpaceOperation = ({
	operationCheckResult,
	operation,
	contentType,
}: canPerformSpaceOperationParams): boolean => {
	// prettier-ignore
	const permittedOperations = (operationCheckResult?.space?.operations ?? []) as SpaceOperationsQuery_space_operations[];

	return permittedOperations.some(
		(permittedOperation) =>
			permittedOperation?.operation === operation && permittedOperation?.targetType === contentType,
	);
};

/** Functions to query and check operation result */

/**
 * Retrieve operations for content with contentId, then check if user permitted to perform `operation` on target `contentType`
 * @param contentId id of target entity
 * @param operation operation type
 * @param contentType if empty, then content type of content with id contentId will be used
 */
export const checkOperationForContent = async ({
	contentId,
	operation,
	contentType,
}: checkContentOperationParams): Promise<boolean> => {
	const apolloClient = getApolloClient();
	const contentOperationsQueryResult: ApolloQueryResult<ContentOperationsQueryType> =
		await apolloClient.query({
			query: ContentOperationsQuery,
			variables: {
				contentId,
			},
		});
	return canPerformContentOperation({
		operationCheckResult: contentOperationsQueryResult.data,
		operation,
		contentType,
	});
};

/**
 * Retrieve operations space, then check if user permitted to perform `operation` on target `contentType` within space
 */
export const checkOperationForSpace = async ({
	spaceKey,
	operation,
	contentType,
}: checkSpaceOperationParams): Promise<boolean> => {
	const apolloClient = getApolloClient();
	const spaceOperationsQueryResult: ApolloQueryResult<SpaceOperationsQueryType> =
		await apolloClient.query({
			query: SpaceOperationsQuery,
			variables: {
				spaceKey,
			},
		});
	return canPerformSpaceOperation({
		operationCheckResult: spaceOperationsQueryResult.data,
		operation,
		contentType,
	});
};

type UseCanPerformContentOperationProps = checkContentOperationParams & {
	status?: (string | null)[] | null;
};

/**
 * A hook that checks if the current user can perform the specified `operation` on the content with the given ID and type.
 *
 * Returns `undefined` when the permission is loading,
 * and then `true` if the user can perform the operation, and `false` otherwise.
 */
export const useCanPerformContentOperation = ({
	contentId,
	operation,
	contentType,
	status,
}: UseCanPerformContentOperationProps): UseCanPerformContentOperationType => {
	// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
	const graphQLResult = useQuery<ContentOperationsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentOperationsQuery,
		{
			variables: { contentId, status },
		},
	);
	// Only show the publish button if the user has permission to update (edit) this content.
	let canPerformOperation = undefined;
	if (graphQLResult?.data) {
		canPerformOperation = canPerformContentOperation({
			operationCheckResult: graphQLResult?.data,
			operation,
			contentType,
		});
	}

	return [canPerformOperation, graphQLResult];
};

/**
 * A hook that checks if the current user can perform the specified `operation` in the given space on the specified `contentType`.
 *
 * Returns `undefined` when the permission is loading,
 * and then `true` if the user can perform the operation, and `false` otherwise.
 */
export const useCanPerformSpaceOperation = ({
	spaceKey,
	operation,
	contentType,
}: checkSpaceOperationParams): UseCanPerformSpaceOperationType => {
	// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
	const graphQLResult = useQuery<SpaceOperationsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceOperationsQuery,
		{
			variables: { spaceKey },
		},
	);
	// Only show the publish button if the user has permission to update (edit) this content.
	let canPerformOperation = undefined;
	if (graphQLResult?.data) {
		canPerformOperation = canPerformSpaceOperation({
			operationCheckResult: graphQLResult?.data,
			operation,
			contentType,
		});
	}

	return [canPerformOperation, graphQLResult];
};
