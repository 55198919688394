import { createStore, createHook, createActionsHook } from 'react-sweet-state';
import type { Action, BoundActions } from 'react-sweet-state';
import type { ComponentType } from 'react';
import React, { useMemo } from 'react';

import type { ModalDialogProps } from '@atlaskit/modal-dialog';

import type { ModalFrame } from './DialogsStateContainer';
import { dialogKeyState } from './dialog-key-state';

type ModalDialogOnCloseArgs = Parameters<Required<ModalDialogProps>['onClose']>;
type ModalDialogOnCloseCompleteArgs = Parameters<Required<ModalDialogProps>['onCloseComplete']>;

/**
 * component's original props
 */
export type DialogsContainerProps = {
	onClose(...args: Partial<ModalDialogOnCloseArgs>): void;
	onCloseComplete(...args: Partial<ModalDialogOnCloseCompleteArgs>): void;
};

export interface State {
	stack: ModalFrame[];
}

const onChildClose =
	(key: number, ...args: Partial<ModalDialogOnCloseArgs>): Action<State> =>
	({ setState, getState }) => {
		const state = getState();
		// A child dialog called its onClose prop

		// Find the stack frame for the respective child
		const childFrame = state.stack.find((frame) => frame.key === key);

		if (!childFrame) {
			// This is harmless: it can happen when trying to close the dialog multiple times from different places.
			return;
		}

		if (childFrame.originalProps.onClose) {
			childFrame.originalProps.onClose(...args);
		}

		// Don't remove this dialog from the stack; instead mark it as not alive. This will pull the
		// component from its enclosing <ModalTransition>, but keep the <ModalTransition> around, for now.
		// The child that we provide to the <ModalTransition> has an onCloseComplete prop, to trigger the
		// final cleanup.

		setState({
			stack: state.stack.map((frame) =>
				frame === childFrame
					? {
							...frame,
							alive: false,
						}
					: frame,
			),
		});
	};

const onChildCloseComplete =
	(key: number, ...args: Partial<ModalDialogOnCloseCompleteArgs>): Action<State> =>
	({ getState, setState }) => {
		// A child dialog called its onCloseComplete prop

		const state = getState();

		// Find the stack frame for the respective child
		const childFrame = state.stack.find((frame) => frame.key === key);

		if (!childFrame) {
			// This is harmless: it can happen when trying to close the dialog multiple times from different places.
			return;
		}

		if (childFrame.originalProps.onCloseComplete) {
			childFrame.originalProps.onCloseComplete(...args);
		}

		// The transition has completed, so complete the cleanup: remove the
		// frame from the stack to pull the <ModalTransition>.

		setState({
			stack: state.stack.filter((frame) => frame.key !== childFrame.key),
		});
	};

const actions = {
	showModal:
		(
			componentClass: ComponentType<any>,
			originalProps?: any,
			children?: React.ReactNode,
		): Action<State, void, (...args: any[]) => void | Promise<void>> =>
		({ getState, setState, dispatch }) => {
			const key = dialogKeyState.nextKey;
			const props: any = originalProps || {};
			const onClose: DialogsContainerProps['onClose'] = (...args) =>
				dispatch(onChildClose(key, ...args));
			const onCloseComplete: DialogsContainerProps['onCloseComplete'] = (...args) =>
				dispatch(onChildCloseComplete(key, ...args));

			const component = React.createElement(
				componentClass,
				{
					...props,
					onClose,
					onCloseComplete,
				},
				children,
			);

			const state = getState();
			setState({
				stack: [
					...state.stack,
					{
						key,
						component,
						alive: true,
						originalProps: {
							onClose: props?.onClose,
							onCloseComplete: props?.onCloseComplete,
						},
					},
				],
			});

			// Returns a method that the caller can use to close the dialog (though, most often,
			// a child would self-close)
			return onClose;
		},
};

const store = createStore<State, typeof actions>({
	initialState: {
		stack: [],
	},
	actions,
	name: 'DialogsStore',
});

export type DialogBoundActionsType = BoundActions<State, typeof actions>;

/**
 * This is a work around to enforce the type requirement for the modal component.
 * For some reason the types don't resolve correctly in the bound actions
 */
const getTypedActions = (actions: DialogBoundActionsType) => {
	const showModal = <T extends DialogsContainerProps>(
		component: ComponentType<T>,
		originalProps: Omit<T, keyof DialogsContainerProps> & Partial<DialogsContainerProps>,
	) => {
		actions.showModal(component, originalProps);
	};
	return { showModal };
};

const useDialogActions = createActionsHook(store);
export const useDialogs = () => {
	const actions = useDialogActions();

	return useMemo(() => getTypedActions(actions), [actions]);
};

const useDialogsStore = createHook(store);
export const useDialogsState = () => {
	const [state, actions] = useDialogsStore();

	return useMemo(() => ({ state, ...getTypedActions(actions) }), [actions, state]);
};
